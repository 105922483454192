import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly STORAGE_KEY = 'selectedDeliveryDetails';

  setItem(setItem, data) {
    localStorage.setItem(setItem, data);
  }

  getItem(getItem) {
    return localStorage.getItem(getItem);
  }

  removeItem(remItem) {
    localStorage.removeItem(remItem);
  }

  removeAllItem() {
    localStorage.clear();
  }

  checkLength() {
    return localStorage.length;
  }

  setDeliveryDetails(details: { pincode: number; latitude: number; longitude: number }) {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(details));
  }

  getDeliveryDetails(): { pincode: number; latitude: number; longitude: number } | null {
    const data = localStorage.getItem(this.STORAGE_KEY);
    return data ? JSON.parse(data) : null;
  }

  clearDeliveryDetails() {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}