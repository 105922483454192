<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Wallet</h2>
        <div class="hr"></div>
    </div>
</div>

<div style="margin-top: 2%">
    <div class="section-heading">
        <h3 class="h3_font_size">Wallet Balance: {{totalCredits}}</h3>
    </div>
</div>


<div *ngIf="dataSource?.data?.length > 0; else noData" class="responsive-grid">
    <div *ngFor="let element of dataSource.data" class="transaction-card">
        <div class="field">
            <label>Date & Time:</label>
            <span>{{ element.transactionDate | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
        </div>
        <div class="field">
            <label>Description:</label>
            <span>{{ element.description }}</span>
        </div>
        <div class="field">
            <label>Trans Ref No:</label>
            <span *ngIf="element.transactionRefNo && element.transactionRefNo !== 'null'">
                {{ element.transactionRefNo }}
            </span>
            <span *ngIf="!element.transactionRefNo || element.transactionRefNo === 'null'">
                Not Available
            </span>
        </div>
        <div class="field">
            <label>Status:</label>
            <span [ngClass]="getStatusClass(element.status)">
                {{ element.status }}
            </span>
        </div>
        <div class="field">
            <label>Amount:</label>
            <span *ngIf="element.status == 'Credit'" style="color: green;">+ {{ element.credits | currency:
                'INR'}}</span>
            <span *ngIf="element.status != 'Credit'" style="color: red;">- {{ element.credits | currency: 'INR'}}</span>
        </div>
        <div class="field">
            <label>Balance:</label>
            <span>{{ element.balance | currency: 'INR' }}</span>
        </div>
    </div>
</div>

<ng-template #noData>
    <p style="text-align: center; padding: 20px;">No transactions found.</p>
</ng-template>