<form [formGroup]="createSetUpForm">
  <div class="section-heading">
    <h5>Search Delivery Location</h5>
  </div>
  <div style="display: flex;justify-content: center;">
    <div style="width: 90%;">
      <div style="padding-bottom: 10px; display: flex; align-items: center; position: relative;">
        <input type="text" class="form-control" placeholder="Search delivery location" autocorrect="off"
          autocapitalize="off" spellcheck="off" #search [disabled]="!newDeliveryDetails"
          (input)="onSearchInput(search.value)" (keydown.enter)="$event.preventDefault()" />

        <!-- Clear icon -->
        <mat-icon *ngIf="searchInput" class="fetch_icon" (click)="clearSearch()">close</mat-icon>

        <!-- GPS icon -->
        <mat-icon class="fetch_icon" (click)="fetchCurrentLoc()" matTooltip="Fetch current location">
          my_location
        </mat-icon>
      </div>

      <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom">
        <agm-marker [latitude]="latitudeMap" [longitude]="longitudeMap" [markerDraggable]="newDeliveryDetails"
          (dragEnd)="markerDragEnd($event)">
        </agm-marker>
      </agm-map>
    </div>
  </div>

  <div *ngIf="showToast">
    <p class="h7">
      Delivery to this pincode {{ pincode }} is currently not available
    </p>
  </div>

  <div class="section-heading" style="max-height: 30px" *ngIf="!showClickButton">
    <h6 style="margin-top: 15px">Select Preferred Location</h6>
  </div>
  <div style="max-height: 200px;overflow: auto;" *ngIf="!showClickButton">
    <!-- <div class="col-lg-12" *ngFor="let item of locationSetupList" -->
    <div *ngFor="let locationSetup of locationSetupList" class="col-lg-12"
      style="display: flex;height: 130px;align-items: center;">
      <div class="location_div">
        <div class="location-name">
          {{ locationSetup.locationName }}
        </div>
        <div *ngIf="locationSetup.address1" class="address1">
          {{ locationSetup.address1 }}
        </div>
        <div *ngIf="locationSetup.address2" class="address2">
          {{ locationSetup.address2 }}
        </div>
        <div *ngIf="locationSetup.areaNames" class="pincode">
          {{ locationSetup.areaNames }}
        </div>
        <div *ngIf="locationSetup.pincode" class="pincode">
          {{ locationSetup.pincode }}
        </div>
        <div class="proceed_div">
          <button (click)="onCreateSetUpClick(locationSetup)" type="button" class="proceed_btn">
            Proceed
            <mat-icon class="proceed_icon">arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

</form>