import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { PaymentHistoryDto } from "src/app/shared/models/payment-history-dto";
import { WebOrderDto } from "src/app/shared/models/weborderdto";
import { CommonService } from "src/app/shared/services/CommonService";
import { InvoiceHistoryService } from "src/app/shared/services/invoice-history.service";
import { ToastService } from "src/app/shared/services/toastr.service";

@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html",
  styleUrls: ["./payment-history.component.scss"],
})
export class PaymentHistoryComponent implements OnInit {
  googleCustomer: GoogleCustomer;
  fbCustomer: FBCustomer;
  dataSource: MatTableDataSource<PaymentHistoryDto>;
  paymentHistory: PaymentHistoryDto[] = [];
  displayedColumns: string[] = ["dateTime", "amount", "transId", "bankTransId", "status"];

  private onViewSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private invoiceHistoryService: InvoiceHistoryService
  ) {
    this.dataSource = new MatTableDataSource(this.paymentHistory);
  }

  ngOnInit(): void {
    this.viewWebOrderHistory();
  }

  viewWebOrderHistory() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.fbCustomer = JSON.parse(this.localStorage.getItem("fb_loggedIn_details"));
    var customerEmail: string;
    if (this.googleCustomer != null) {
      customerEmail = this.googleCustomer.email;
    } else {
      customerEmail = this.fbCustomer.email;
    }

    this.onViewSubscription = this.invoiceHistoryService.getWebOrderPaymentHistory(this.apiFactory.getWebOrderPaymentHistory, customerEmail)
      .subscribe((data: any) => {
        console.log(data);
        if (data.length > 0) {
          this.paymentHistory = data;
          this.dataSource.data = this.paymentHistory;
        } else {
          this.toastService.showErrorToast("No Data Available");
        }
      });
  }

  getStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'success':
        return 'status success';
      case 'aborted':
        return 'status aborted';
      case 'failure':
        return 'status failure';
      default:
        return 'status';
    }
  }

}
