import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { WalletTransaction } from 'src/app/shared/models/wallet-transaction';
import { CommonService } from 'src/app/shared/services/CommonService';
import { InvoiceHistoryService } from 'src/app/shared/services/invoice-history.service';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { ToastService } from '../../../../shared/services/toastr.service';


@Component({
  selector: 'app-wallet-transaction',
  templateUrl: './wallet-transaction.component.html',
  styleUrls: ['./wallet-transaction.component.scss']
})
export class WalletTransactionComponent implements OnInit {

  googleCustomer: GoogleCustomer;
  dataSource: MatTableDataSource<WalletTransaction>;
  walletTransaction: WalletTransaction[] = [];
  totalCredits: number = 0;
  displayedColumns: string[] = ["transactionDate", "description", "transactionRefNo", "status", "credits", "balance"];

  private onViewSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private commonService: CommonService,
  ) {
    this.dataSource = new MatTableDataSource(this.walletTransaction);
  }

  ngOnInit(): void {
    this.getWalletHistory();
  }

  getWalletHistory() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    var customerEmail: string;
    if (this.googleCustomer != null) {
      customerEmail = this.googleCustomer.email;
    }

    // this.walletTransaction[0] = new WalletTransaction();
    // this.walletTransaction[0].description = "1st Time User Offer";
    // this.walletTransaction[0].credits = 100;
    // this.walletTransaction[0].transactionDate = "2023-02-01 12:50:10";
    // this.walletTransaction[0].status = "Credit";
    // this.walletTransaction[0].validTill = "2024-02-01 11:59:59";
    // this.walletTransaction[1] = new WalletTransaction();
    // this.walletTransaction[1].description = "Purchase";
    // this.walletTransaction[1].transactionRefNo = "TID0001";
    // this.walletTransaction[1].credits = 50;
    // this.walletTransaction[1].transactionDate = "2023-02-02 11:50:10";
    // this.walletTransaction[1].status = "Debit";
    // this.walletTransaction[1].validTill = "";
    // this.walletTransaction[2] = new WalletTransaction();
    // this.walletTransaction[2].description = "Purchase";
    // this.walletTransaction[2].transactionRefNo = "TID0001";
    // this.walletTransaction[2].credits = 25;
    // this.walletTransaction[2].transactionDate = "2023-02-02 12:51:00";
    // this.walletTransaction[2].status = "Credit";
    // this.walletTransaction[2].validTill = "2024-02-02 11:59:59";
    // this.walletTransaction[3] = new WalletTransaction();
    // this.walletTransaction[3].description = "Purchase";
    // this.walletTransaction[3].transactionRefNo = "TID0002";
    // this.walletTransaction[3].credits = 20;
    // this.walletTransaction[3].transactionDate = "2023-02-03 10:50:15";
    // this.walletTransaction[3].status = "Debit";
    // this.walletTransaction[3].validTill = "";
    // this.walletTransaction[4] = new WalletTransaction();
    // this.walletTransaction[4].description = "Purchase";
    // this.walletTransaction[4].transactionRefNo = "TID0002";
    // this.walletTransaction[4].credits = 10;
    // this.walletTransaction[4].transactionDate = "2023-02-03 10:52:15";
    // this.walletTransaction[4].status = "Credit";
    // this.walletTransaction[4].validTill = "2023-02-03 11:59:59";
    // this.walletTransaction[5] = new WalletTransaction();
    // this.walletTransaction[5].description = "Purchase";
    // this.walletTransaction[5].transactionRefNo = "TID0003";
    // this.walletTransaction[5].credits = 10;
    // this.walletTransaction[5].transactionDate = "2023-02-04 12:50:10";
    // this.walletTransaction[5].status = "Debit";
    // this.walletTransaction[5].validTill = "";
    // this.walletTransaction[6] = new WalletTransaction();
    // this.walletTransaction[6].description = "Purchase";
    // this.walletTransaction[6].transactionRefNo = "TID0003";
    // this.walletTransaction[6].credits = 5;
    // this.walletTransaction[6].transactionDate = "2023-02-04 12:55:10";
    // this.walletTransaction[6].status = "Credit";
    // this.walletTransaction[6].validTill = "2024-02-04 11:59:59";
    // this.walletTransaction[7] = new WalletTransaction();
    // this.walletTransaction[7].description = "Purchase";
    // this.walletTransaction[7].transactionRefNo = "TID0004";
    // this.walletTransaction[7].credits = 50;
    // this.walletTransaction[7].transactionDate = "2023-02-05 12:50:10";
    // this.walletTransaction[7].status = "Debit";
    // this.walletTransaction[7].validTill = "";
    // this.walletTransaction[8] = new WalletTransaction();
    // this.walletTransaction[8].description = "Purchase";
    // this.walletTransaction[8].transactionRefNo = "TID0004";
    // this.walletTransaction[8].credits = 25;
    // this.walletTransaction[8].transactionDate = "2023-02-05 12:52:10";
    // this.walletTransaction[8].status = "Credit";
    // this.walletTransaction[8].validTill = "2024-02-07 11:59:59";
    // this.walletTransaction[9] = new WalletTransaction();
    // this.walletTransaction[9].description = "Purchase";
    // this.walletTransaction[9].transactionRefNo = "TID0005";
    // this.walletTransaction[9].credits = 5;
    // this.walletTransaction[9].transactionDate = "2023-02-07 12:50:10";
    // this.walletTransaction[9].status = "Debit";
    // this.walletTransaction[9].validTill = "";
    // this.walletTransaction.forEach(element => {
    //   if (element.status == "Credit") {
    //     this.totalCredits = this.totalCredits + element.credits
    //     element.balance = this.totalCredits
    //   }
    //   else {
    //     this.totalCredits = this.totalCredits - element.credits
    //     element.balance = this.totalCredits
    //   }
    // })
    // this.dataSource.data = this.walletTransaction;

    this.onViewSubscription = this.commonService.getWalletTransaction(this.apiFactory.getWalletTransactionByEmail, customerEmail)
      .subscribe((data: any) => {
        console.log(data);
        if (data.length > 0) {
          this.walletTransaction = data;

          this.walletTransaction.forEach(element => {
            if (element.status == "Credit") {
              this.totalCredits = Number((Number(this.totalCredits.toFixed(2)) + Number(element.credits.toFixed(2))).toFixed(2));
              element.balance = this.totalCredits
            }
            else {
              this.totalCredits = Number((Number(this.totalCredits.toFixed(2)) - Number(element.credits.toFixed(2))).toFixed(2));
              element.balance = this.totalCredits
            }
          })
          this.walletTransaction.reverse();
          this.dataSource.data = this.walletTransaction;
        } else {
          this.toastService.showErrorToast("No Data Available");
        }
      });
  }

  getStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'debit':
        return 'status debit';
      case 'credit':
        return 'status credit';
      default:
        return 'status';
    }
  }
}
